<template>
  <div>
    <div style="height: fit-content">
      <b-row class="d-flex justify-content-between">
        <b-col>
          <div  class="d-flex flex-wrap">
            <!-- Name -->
            <b-form-group class="flex-grow-1">
              <label for="location-name" class="font-weight-bold"> {{ $t("backoffice.settings.name") }}</label>
              <b-form-input
                id="location-name"
                v-model="currentLocation.name"
                type="text"
                style="min-width: 200px"
                :placeholder="$t('form.type-placeholder')"
              />
            </b-form-group>
            <!-- Location -->
            <b-form-group class="ml-sm-1 flex-grow-1">
              <label for="location-name" class="font-weight-bold"> {{ $t("backoffice.settings.location-label") }}</label>
              <b-form-input
                id="location-name"
                v-model="currentLocation.location"
                list="my-list-id"
                type="text"
                @input="fetchOptions()"
                :state="isValid"
                :placeholder="$t('form.type-placeholder')"
              />
              <b-form-datalist id="my-list-id">
                <option v-for="(option, index) in options" :key="index">
                  {{ option.description }}
                </option>
              </b-form-datalist>

            </b-form-group>
          </div>
          <label for="location-name" class="font-weight-bold">{{$t('contacts.list-locations')}}</label>
          <div v-if="mapPlaces.length === 0" class="d-flex flex-wrap justify-content-center bg-light px-1 pt-50 mt-50 mb-1 rounded border"><small class="text-muted pt-50 pb-1 font-weight-bold">{{$t('contacts.no-locations')}}</small></div>

          <!-- All locations -->
          <div v-else>
            <div v-for="(location, index) in mapPlaces" :key="index" class="d-flex flex-wrap  bg-light px-1 pt-50 mt-50 mb-1 rounded border">
              <!-- Name -->
              <b-form-group class="flex-grow-1">
                <label for="location-name" class="font-weight-bold"> {{ $t("backoffice.settings.name") }}</label>
                <b-form-input
                  id="location-name"
                  plaintext
                  v-model="mapPlaces[index].name"
                  type="text"
                  style="min-width: 200px"
                  @input="fetchOptions(index)"
                  :placeholder="$t('form.type-placeholder')"
                />
              </b-form-group>
              <!-- Location -->
              <b-form-group class="ml-sm-1 flex-grow-1">
                <label for="location-name" class="font-weight-bold"> {{ $t("backoffice.settings.location-label") }}</label>
                <!-- {{ mapPlaces[index].location }} -->
                <b-form-input
                  id="location-name"
                  v-model="mapPlaces[index].location"
                  type="url"
                  plaintext
                  :list="`my-list-id-${index}`"
                  :placeholder="$t('form.type-placeholder')"
                  @input="fetchOptions(index)"
                />
                <b-form-datalist id="my-list-id">
                  <option v-for="(option, index) in options" :key="index">
                    {{ option.description }}
                  </option>
                </b-form-datalist>
              </b-form-group>
              <feather-icon icon="Trash2Icon" size="16" class="ml-50 mt-2 text-primary pointer" @click="removeItem(index)"/>
            </div>
          </div>
          <places-map
            v-model="mapPlaces"
            ref="map"
            :placeholder="$t('organizations.edit.locations.placeholder')"
            :locations="mapPlaces"
            class="mt-2"
            :in-container="true"
            style="height:300px"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import formValidation from '@core/comp-functions/forms/form-validation';
import PlacesMap from '@core/components/places-map-input/PlacesMap.vue';
import { Loader } from '@googlemaps/js-api-loader';

export default {
  components: {
    PlacesMap,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    contactLocations: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      currentLocation: {
        name: null,
        location: null,
      },
      locations: [],
      isLinkSectionActive: false,
      isFirstLinkSectionVisible: true,
      isFirstLocationSectionVisible: false,
      google: null,
      autocomplete: null,
      placesService: null,

      bounds: null,

      selectedPlacesIds: [],
      options: [],
      mapPlaces: [],
      isValid: null,
      savedLOcations: [],
      isRemoving: false,
    };
  },
  setup() {
    const {
      getValidationState,
    } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  created() {
    this.mapPlaces = this.contactLocations || [];
    this.mapPlaces = this.mapPlaces.map((item) => ({ location: item.address, name: item.label, latitude: item.latitude, longitude: item.longitude }));
  },
  async mounted() {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });
    this.google = await loader.load();

    this.autocompleteService = new this.google.maps.places.AutocompleteService();
    this.placesService = new this.google.maps.places.PlacesService(this.$refs.map.getMap());

    // Bias the autocomplete results towards current map's viewport.
    this.$refs.map.getMap().addListener('bounds_changed', () => {
      this.bounds = this.$refs.map.getMap().getBounds();
    });

    if (this.mapPlaces) {
      this.mapPlaces.forEach((location) => {
        this.selectedPlacesIds.push({
          code: `dummy-id-${location.key}`, // use dummy id to avoid fetching the placeId from geocoding.
          description: location.name,
        });
      });
    }
  },
  methods: {
    async saveLocations() {
      this.mapPlaces = this.mapPlaces.filter(({ location }) => location !== '');
      this.currentLocation = {};
      const address = this.mapPlaces.map((item) => ({ name: item.location, label: item.name, latitude: item.latitude, longitude: item.longitude }));
      if (address.length > 0 || this.isRemoving) {
        this.$emit('locations', address);
      } else {
        this.notifyError(this.$t('organizations.backoffice.settings.locations.messages.error'));
      }
      this.isRemoving = false;
    },
    async fetchOptions(index) {
      const promise = new Promise((resolve) => {
        this.autocompleteService.getPlacePredictions(
          {
            input: this.currentLocation?.location || this.mapPlaces[index].location,
            bounds: this.bounds,
          },
          (predictions, status) => {
            if (status !== this.google.maps.places.PlacesServiceStatus.OK) {
              resolve([]);
              return;
            }

            resolve(predictions);
          },
        );
      });
      const predictions = await promise;

      this.options = predictions
        .map(({ place_id: code, description }) => ({ code, description }));
      this.handlePlaceSelected(index);
    },
    handlePlaceSelected(index) {
      const selectedOptions = this.options.filter(({ description }) => description === this.currentLocation.location || description === this.mapPlaces[index]?.location);
      const option = selectedOptions[selectedOptions.length - 1];
      if (!option) {
        return;
      }
      this.placesService.getDetails({ placeId: option.code }, (placeResult, status) => {
        if (status === this.google.maps.GeocoderStatus.OK) {
          const postalCode = placeResult.address_components
            .find(({ types }) => types.includes('postal_code'))?.long_name;
          const locality = placeResult.address_components.find(({ types }) => types.includes('locality'))?.long_name;
          const region = placeResult.address_components
            .find(({ types }) => types.includes('administrative_area_level_1')).long_name;
          const location = {
            name: this.currentLocation.name || this.mapPlaces[index]?.name || option.description,
            latitude: this.currentLocation.location !== '' ? placeResult.geometry.location.lat() : '',
            longitude: this.currentLocation.location !== '' ? placeResult.geometry.location.lng() : '',
            postalCode,
            locality,
            region,
            location: this.currentLocation.location || this.mapPlaces[index]?.location,
          };
          this.isValid = true;
          this.mapPlaces.push(location);
          this.currentLocation = {};
        }
        this.saveLocations();
      });
    },
    removeItem(index) {
      this.mapPlaces.splice(index, 1);
      this.isRemoving = true;
      this.saveLocations();
    },
  },
};
</script>
