<template>
  <b-tabs :nav-class="member ? 'd-none' : ''">
    <b-tab
      active
      :title="$t('contacts.add-contact')"
      content-class="mt-2"
      class="mt-2"
      title-link-class="px-0 font-weight-bolder"
    >
      <!-- Body -->
      <!-- Form -->
      <b-form>
        <b-row>
          <b-col cols="12" lg="6">
            <!-- Name -->
            <b-form-group
              :label="`${$t('members.edit.name.label')}*`"
              label-for="profile-name"
              class="font-weight-bold"
            >
              <b-form-input
                id="profile-name"
                v-model="profile.name"
                autofocus
                trim
                :placeholder="$t('members.edit.name.placeholder')"
                :disabled="isSaving"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <!-- Surname -->
            <b-form-group
              :label="`${$t('members.edit.surname.label')}`"
              label-for="profile-surname"
              class="font-weight-bold"
            >
              <b-form-input
                id="profile-surname"
                v-model="profile.surname"
                autofocus
                trim
                :placeholder="$t('members.edit.surname.placeholder')"
                :disabled="isSaving"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6">
            <!-- JobTitle -->
            <b-form-group
              :label="`${$t('members.edit.job-title.label')}`"
              label-for="profile-job-title"
              class="font-weight-bold"
            >
              <b-form-input
                id="profile-job-title"
                v-model="profile.jobTitle"
                trim
                :placeholder="$t('members.edit.job-title.placeholder')"
                :disabled="isSaving"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <!-- Organizations -->
            <b-form-group>
              <label for="profile-organizations" class="font-weight-bold">{{ $t('societies.title') }}</label>
              <members-select
                v-model="profile.organizations"
                clearable
                multiple
                searchable
                dir="ltr"
                input-id="profile-organizations"
                :placeholder="$t('form.select-placeholder')"
                :fetch-data="fetchData"
                class="select-multiple"
                label="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr></hr>
        <b-row class="mt-1 mx-1">
          <b-col>
            <b-tabs pills content-class="mt-2" active-nav-item-class="bg-transparent text-primary shadow-none">
              <b-tab active>
                <template #title>
                  <b-icon icon="envelope" scale="1" />
                  <span>{{ $t('backoffice.settings.subtitle-emails') }}</span>
                </template>
                <!-- Emails -->
                <emails :mails="profile.emails" @save-emails="handleSaveEmails" />
              </b-tab>
              <b-tab>
                <template #title>
                  <b-icon icon="link" scale="1.2" />
                  <span>{{ $t('backoffice.settings.subtitle-links') }}</span>
                </template>
                <!-- Links -->
                <links :profile-links="profile.links" @save-links="handleSaveLinks" />
              </b-tab>
              <b-tab>
                <!-- Phones -->
                <template #title>
                  <b-icon icon="telephone" scale="0.9" />
                  <span>{{ $t('backoffice.settings.subtitle-phones') }}</span>
                </template>
                <phones :profile-phones="profile.phones" @save-phones="handleSavePhones" />
              </b-tab>
              <b-tab>
                <!-- Locations -->
                <template #title>
                  <b-icon icon="map" scale="0.9" />
                  <span>{{ $t('backoffice.settings.subtitle-locations') }}</span>
                </template>
                <locations :contact-locations="profile.locations" @locations="handleLocationsAdded" class="mb-2"/>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
        <hr></hr>
        <b-button variant="primary float-right mb-1" :disabled="isDisabled" @click="onSubmit">
          <b-spinner v-if="isSaving" small />
          {{ $t("form.actions.save") }}
        </b-button>
      </b-form>
    </b-tab>
    <b-tab
      :title="$t('contacts.multiple-contacts')"
      content-class="mt-2"
      class="mt-2"
      title-link-class="px-0 ml-2 font-weight-bolder"
    >
      <div >
        <span
          class="d-flex align-items-center justify-content-between mb-1"
        >
          <b-button
            variant="link"
            class="p-0 pointer font-weight-bold"
            @click="uploadFileVisible = !uploadFileVisible"
          >
            {{ $t("contacts.import") }}
          </b-button>
          <b-badge
            variant="light-primary"
            class="ml-25 font-weight-normal pointer"
            @click="downloadCsv()"
          >
            <feather-icon icon="DownloadIcon" class="mr-25" />{{$t('download.csv')}}
          </b-badge>
        </span>
      </div>
      <div v-if="uploadFileVisible" >
        <file-upload
          v-model="csvFile"
          type="csv"
          class="w-100 my-1"
          cancelable
          :placeholder="$t('media.form.file.placeholder')"
          :drop-placeholder="$t('media.form.file.drop-placeholder')"
        />
      </div>
      <div class="mb-1 d-flex flex-wrap justify-content-between align-items-center">
        <div class="d-flex mr-2 mt-50">
          <b-form-checkbox
            v-model="forceInsert"
            switch
            class="mb-1 ml-50"
          />
          <small><label for="published" class="font-weight-bold mt-25"> {{ $t('contacts.duplicate-message') }}</label></small>
        </div>
        <b-button variant="primary"  class="mt-50 " :disabled="csvFile === null" @click="onSubmit">
          <b-spinner v-if="isSaving" small />
          {{ $t("form.actions.save") }}
        </b-button>
        </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import ActiveLinksLabels from "@/@core/constants/ActiveLinksLabels";
import MembersPlaceholder from "@/assets/images/placeholders/light/members.svg";
import HeaderPlaceholder from "@/assets/images/placeholders/light/header.jpeg";
import { required, max } from "@validations";
import { getImageResource } from "@/@core/utils/image-utils";
import Links from "@/views/apps/contacts/components/Links.vue";
import Emails from "@/views/apps/contacts/components/Emails.vue";
import Phones from "@/views/apps/contacts/components/Phones.vue";
import OrganizationsAPI from "@/views/apps/organization/store/OrganizationsAPI";
import MembersSelect from "@/views/apps/member/components/MembersSelect.vue";
import FileUpload from "@core/components/files/FileUpload.vue";
import Locations from '@/views/apps/contacts/components/Locations.vue';

export default {
  name: "CreateContactsModal",
  components: {
    ValidationProvider,
    Links,
    Emails,
    Phones,
    MembersSelect,
    FileUpload,
    Locations,
  },
  directives: { BTooltip: VBTooltip },
  mixins: [ToastNotificationsMixin],
  props: {
    visible: Boolean,
    member: {
      type: Object,
      required: false,
    },
    isBackoffice: Boolean,
  },
  data() {
    return {
      profile: {
        avatarURL: null,
        backgroundURL: null,
        name: "",
        surname: "",
        jobTitle: "",
        summary: "",
        organizations: null,
        locations: [],
        links: [],
        emails: [],
        phones: [],
      },
      avatar: null,
      background: null,

      isSaving: false,

      required,
      max,
      isAddOrganizationModalVisible: false,
      isInputDescriptionModalVisible: false,
      types: [],
      memberType: {},
      enabledOrganizationApp: false,
      forceInsert: false,
      uploadFileVisible: false,
      csvFile: null,
      csvTemplateEmails: {
        Surname: 'Musk',
        Name: 'Elon',
        Position: 'CEO',
        Email: 'musk@tesla.com',
        Language: 'English',
        Organization: 'Tesla',
        Address: 'Starbase, Texas',
        'Postal code': '75001',
        City: 'Texas',
        Country: 'United States',
        'VAT Number': 'Example VAT number',
        Phone: '1234567',
        Website: 'https//www.tesla.com',
        'Legal Name': 'Tesla, Inc',
        'Number of employees': '110000',

      },
    };
  },
  computed: {
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    linkLabelsOptions() {
      return ActiveLinksLabels.map((label) => ({
        text: this.$t(`links.label.${label}`),
        value: label,
      }));
    },
    isDisabled() {
      return this.profile.name === "";
    },
    avatarPlaceholder() {
      return MembersPlaceholder;
    },
    backgroundPlaceholder() {
      return HeaderPlaceholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    staff() {
      return this.$store.getters.currentCollective.isStaff;
    },
  },
  watch: {
    member() {
      this.profile = {...this.member}
    },
  },
  async created() {
    if (!this.member) {
      this.resetForm();
    } else {
      this.profile = {...this.member}
    }
  },
  methods: {
    handleLocationsAdded(locations) {
      this.profile.locations = locations;
    },
    fetchData(page, searchString = "") {
      return OrganizationsAPI.fetchPage({
        communityKey:
          this.$store.getters.currentCollective.key,
        listingType: 0,
        searchString: searchString || "",
        typeKey: null,
        page,
        perPage: 16,
      });
    },
    getImageResource,
    async resetForm() {
      this.profile = {
        avatarURL: null,
        backgroundURL: null,
        name: "",
        surname: "",
        jobTitle: "",
        summary: "",
        organizations: [],
        locations: [],
        links: [],
        emails: [],
        phones: [],
      };
      this.avatar = null;
      this.background = null;
      if (this.$refs.refFormObserver) {
        this.$refs.refFormObserver.reset();
      }
    },
    async onSubmit() {
      this.isSaving = true;
      if (this.profile.name != '') {
        try {
          const requestConfig = {
            ...(this.member ? { contactKey: this.member.key } : ''),
            summary: this.profile.summary,
            name: this.profile.name,
            jobTitle: this.profile?.jobTitle,
            organizationKeys: this.profile?.organizations.map(({ key }) => key),
            surname: this.profile.surname || '',
            links: this.profile.links || [],
            emails: this.profile.emails || [],
            phones: this.profile.phones || [],
            locations: this.profile.locations || [],
          };
          await this.$store.dispatch(!this.member ? 'createItem' : 'editItem', {
            item: {
              itemType: 'contacts',
              requestConfig,
            },
          });
          this.notifySuccess(!this.member ? this.$t("contacts.messages.create.success") : this.$t("contacts.messages.edit.success"));
          this.resetForm();
          this.$emit("contact-created");
        } catch (error) {
          this.notifyError(!this.member ? this.$t("contacts.messages.create.error") : this.$t("contacts.messages.edit.error"));
        }
      }
      if (this.csvFile !== null) {
        try {
          await this.$store.dispatch("importFiles", {
            importType: "contacts/import",
            file: this.csvFile,
            customName: "contacts",
            forceInsert: !this.forceInsert,
            forceUpdate: true,
          });
          this.notifySuccess(
            this.$t("backoffice.members.messages.import-success")
          );
          this.csvFile = null;
          this.uploadFileVisible = false;
          this.resetForm();
          this.$emit("contact-created");
        } catch {
          this.notifyError(this.$t("backoffice.members.messages.import-error"));
        }
      }
      this.isSaving = false;
    },
    downloadCsv() {
      let csv = '';
      Object.keys(this.csvTemplateEmails).forEach((row) => {
        csv += `${row};`;
      });
      csv += '\n';
      Object.values(this.csvTemplateEmails).forEach((r) => {
        csv += `${r};`;
      });
      const hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'CSV_template_contacts.csv';
      hiddenElement.click();
    },
    handleSaveLinks(links) {
      this.profile.links = links;
    },
    handleSavePhones(phones) {
      this.profile.phones = phones;
    },
    handleSaveEmails(emails) {
      this.profile.emails = emails;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-editor::v-deep {
  height: auto;
  .ql-editor {
    min-height: 96px;
  }
}
.select-control {
  flex: 1 1 auto;
}
</style>
